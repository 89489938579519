/**
 * Displays a loading animation
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */

'use strict';

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';
import './../stylesheets/loader.less';

/**
 * Constructor.
 * @param {object} [element]
 * @param {object} [loaderOptions] for controlling loader behavior
 * @return {object} this instance
 */
export default function(element, loaderOptions) {
  const defaultElSelector = '.js-loader-container';
  const defaultPlaceholderSelector = '.js-loader-placeholder';
  const SHOW_DELAY_MS = 200;
  let el = null;
  let placeholderEl = null;
  let timeoutId = null;
  let spinner = null;
  const spinnerOptions = {
    lines: 12, // The number of lines to draw
    length: 18, // The length of each line
    width: 5, // The line thickness
    radius: 12, // The radius of the inner circle
    corners: 1, // Corner roundness (0..1)
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#e1e1e5', // #rgb or #rrggbb or array of colors
    speed: 1, // Rounds per second
    trail: 60, // Afterglow percentage
    shadow: false, // Whether to render a shadow
    hwaccel: true, // Whether to use hardware acceleration
    className: 'spinner', // The CSS class to assign to the spinner
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    top: '50%', // Top position relative to parent in px
    left: '50%', // Left position relative to parent in px
    delayedShow: true
  };
  const options = {
    delayedShow: true
  }; // Make the loader to be shown first after 200ms (prevents flickering)
  const sizes = {
    100: {
      radius: 2,
      length: 3,
      width: 2,
      lines: 8
    },
    200: {
      radius: 4,
      length: 6,
      width: 2,
      lines: 10
    },
    300: {
      radius: 8,
      length: 12,
      width: 4,
      lines: 12
    }
  };

  Object.assign(options, loaderOptions);

  return {
    hide(keepElement) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }

      if (el && !keepElement) {
        el.classList.add('hide');
      }

      if (spinner) {
        spinner.stop();
      }
      return this;
    },

    show(size, theme) {
      let isFullscreenLoader = false;

      if (!element) {
        el = document.querySelector(defaultElSelector);

        if (!el) {
          document.body.insertAdjacentHTML('beforeend',
            `<div class="js-loader-container loader-container hide">
              <div class="js-loader-placeholder loader-placeholder"></div>
            </div>`);

          el = document.querySelector(defaultElSelector);
        }

        isFullscreenLoader = true;
      } else if (window.jQuery && element instanceof window.jQuery) {
        el = element.get(0);
      } else if (typeof element === 'string') {
        el = document.querySelector(element);
      } else {
        el = element;
      }

      if (!el) {
        // No element found to show loader in
        return;
      }

      // If spinner is already started, stop it
      if (spinner) {
        spinner.stop();
      }

      // Default size
      let currentSize = sizes['300'];

      // Size of the spinner
      if (size) {
        currentSize = sizes[size];
      }

      spinnerOptions.radius = currentSize.radius;
      spinnerOptions.length = currentSize.length;
      spinnerOptions.width = currentSize.width;
      spinnerOptions.lines = currentSize.lines;

      // Default color or inverted
      if (theme && theme === 'inverted') {
        spinnerOptions.color = '#323233';
      } else {
        spinnerOptions.color = '#e1e1e5';
      }

      const spin = function() {
        if (timeoutId || !options.delayedShow) {
          timeoutId = null;
          // Make sure the element is visible
          el.classList.remove('hide');

          placeholderEl = el;

          if (isFullscreenLoader) {
            placeholderEl = el.querySelector(defaultPlaceholderSelector);
          }

          // Start and show the spinner
          if (Spinner) {
            spinner = new Spinner(spinnerOptions).spin(placeholderEl);
          }
        }
      };

      if (options.delayedShow) {
        timeoutId = setTimeout(spin, SHOW_DELAY_MS);
      } else {
        spin();
      }
      return this;
    }
  };
}
